import React, { useEffect, useState } from "react";
import { DATE_FORMAT, ROUNDTRIP, tripTypes } from "../../../utils/constant";
import FlightResultDeskView from "./flightResultsComp/FlightResultDeskView";
import HeaderNavSearch from "./headerResultPage/HeaderNavSearch";
import ModifySearchBar from "./modifySearchBar/ModifySearchBar";
import "./index.scss";
import flightUpSvg from "../../../assets/svgs/flight-up.svg";
import {
  getFormattedDate,
  useUrlExtract,
  useWindowSize,
} from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { FlightResultsReturn } from "./flightResultsComp/FlightResultsReturn";
import { reviewBooking } from "../../../Api/Booking";
import { SkeletonComp } from "../../common/Skeleton/Skeleton";
import NoResults from "../../NoResults";
import { toastHandler } from "../../../Redux/userReducer/user.action";
import { Search } from "../../homepage/Mobile/Search";
import { useDispatch, useSelector } from "react-redux";
import { searchDetailsHandler } from "../../../Redux/searchReducer/search.action";
import { Button } from "../../common/Button";
import { colorPalette } from "../../../theme/color-palette";

export const DesktopSearch = ({ faresResponse }) => {
  const [selectBtnColor, setSelectBtnColor] = useState(false);
  const [selected, setSelected] = useState("Book");

  const {
    search: {
      flightList,
      searchDetails: {
        departure,
        arrival,
        attendantType: attendantTypeState,
        passengerNumbers: passengerNumbersState,
        tripType: tripTypeState,
        date: flightDate,
      },
    },
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    setPassengerNumbers(passengerNumbersState);
  }, [passengerNumbersState]);

  const [tripType, setTripType] = useState(tripTypeState);
  const [departureLocation, setDepartureLocation] = useState(departure);
  const [arrivalLocation, setArrivalLocation] = useState(arrival);
  const [attendantType, setAttendantType] = useState(attendantTypeState);
  const [flightListState, setFlightListState] = useState([]);
  const [activeInput, setActiveInput] = useState(0);
  const [activeDate, setActiveDate] = useState(0);
  const [passengerNumbers, setPassengerNumbers] = useState({
    ...passengerNumbersState,
  });
  const [date, setDate] = useState({ ...flightDate });
  const [onwardFlightDetails, setOnwardFlightDetails] = useState(``);

  const [innerWidth] = useWindowSize();
  let isTab = innerWidth <= 801 && innerWidth !== 0;

  const { bookingType, bookingId } = useUrlExtract("all");
  let currentParams = useUrlExtract("search");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const departure = flightList?.filter(
      (item) => item?.code === currentParams?.origin
    )?.[0];
    const arrival = flightList?.filter(
      (item) => item?.code === currentParams?.destination
    )?.[0];
    const trips =
      currentParams?.tripType === "OneWay" ? "One Way" : "Round Trip";
    dispatch(
      searchDetailsHandler({
        departure: departure,
        arrival: arrival,
        attendantType: attendantType,
        passengerNumbers: {
          adult: currentParams?.passengers?.adult,
          children: currentParams?.passengers?.child,
          infant: currentParams?.passengers?.infant,
        },
        tripType: trips,
        date: date,
      })
    );
    setTripType(trips);
  }, []);

  const { schedule, returnSchedule } = faresResponse;
  const { DATE4 } = DATE_FORMAT;
  const { primary500, white500 } = colorPalette;

  const handleFlightSelect = (selectedOnwardFlight, selectedFare) => {
    let fareBasisCode = selectedFare?.fareBasisCode;
    let flightId = selectedOnwardFlight?.id;

    setOnwardFlightDetails({
      fareBasisCode: fareBasisCode,
      flightId: flightId,
    });

    if (currentParams?.tripType === tripTypes?.O) {
      reviewBooking({
        sessionId: faresResponse?.sessionId,
        schedule: {
          flightType: "direct",
          fares: [
            {
              fareBasisCode: fareBasisCode,
              flightId: flightId,
            },
          ],
        },
      })
        .then(() => {
          const navigatePath =
            bookingType !== "modify"
              ? `/review-flight?sessionId=${faresResponse?.sessionId}`
              : `/review-flight?sessionId=${faresResponse?.sessionId}&bookingId=${bookingId}&bookingType=modify`;
          navigate(navigatePath);
        })
        .catch((err) => {
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message:
                err?.response?.data?.message || "Something went wrong ...",
              severity: "error",
            })
          );
        });
    }
  };

  const handleFlightSelectReturn = (selectedReturnFlight, selectedFare) => {
    let fareBasisCode = selectedFare?.fareBasisCode;
    let flightId = selectedReturnFlight?.id;

    reviewBooking({
      sessionId: faresResponse?.sessionId,
      schedule: {
        flightType: "direct",
        fares: onwardFlightDetails ? [onwardFlightDetails] : undefined,
      },
      returnSchedule: {
        flightType: "direct",
        fares: [
          {
            fareBasisCode: fareBasisCode,
            flightId: flightId,
          },
        ],
      },
    })
      .then(() => {
        const navigatePath =
          bookingType !== "modify"
            ? `/review-flight?sessionId=${faresResponse?.sessionId}`
            : `/review-flight?sessionId=${faresResponse?.sessionId}&bookingId=${bookingId}&bookingType=modify`;
        navigate(navigatePath);
      })
      .catch((err) => {
        dispatch(
          toastHandler({
            ...openToast,
            open: true,
            message: err?.response?.data?.message || "Something went wrong ...",
            severity: "error",
          })
        );
      });
  };

  const submitDataHandler = () => {
    const { adult, children, infant } = passengerNumbers;
    const { from, to } = date;
    dispatch(
      searchDetailsHandler({
        departure: departureLocation,
        arrival: arrivalLocation,
        attendantType: attendantType,
        passengerNumbers: passengerNumbers,
        tripType: tripType,
        date: date,
      })
    );
    const urlItinerary =
      tripType === ROUNDTRIP
        ? `${departureLocation?.code}-${
            arrivalLocation?.code
          }-${getFormattedDate(from, DATE4)}_${arrivalLocation?.code}-${
            departureLocation?.code
          }-${getFormattedDate(to, DATE4)}`
        : `${departureLocation?.code}-${
            arrivalLocation?.code
          }-${getFormattedDate(from, DATE4)}`;

    navigate(
      `/search-result?tripType=${tripType[0]}&itinerary=${urlItinerary}&paxType=A-${adult}_C-${children}_I-${infant}&atdType=${attendantType}`
    );
  };

  const handleRoundtripToOneway = (noFlight) => {
    const { adult, children, infant } = passengerNumbers;
    const { from } = date;
    dispatch(
      searchDetailsHandler({
        departure:
          noFlight === "noReturn" ? departureLocation : arrivalLocation,
        arrival: noFlight === "noReturn" ? arrivalLocation : departureLocation,
        attendantType: attendantType,
        passengerNumbers: passengerNumbers,
        tripType: "One Way",
        date: date,
      })
    );

    const urlItinerary =
      noFlight === "noReturn"
        ? `${departureLocation?.code}-${
            arrivalLocation?.code
          }-${getFormattedDate(from, DATE4)}`
        : `${arrivalLocation?.code}-${
            departureLocation?.code
          }-${getFormattedDate(from, DATE4)}`;

    navigate(
      `/search-result?tripType=O&itinerary=${urlItinerary}&paxType=A-${adult}_C-${children}_I-${infant}&atdType=${attendantType}`
    );
  };

  return (
    <div className="container-search-page w-full min-h-screen">
      {isTab && bookingType !== "modify" ? (
        <Search
          selected={selected}
          setSelected={setSelected}
          tripType={tripType}
          setTripType={setTripType}
          departureLocation={departureLocation}
          setDepartureLocation={setDepartureLocation}
          arrivalLocation={arrivalLocation}
          setArrivalLocation={setArrivalLocation}
          attendantType={attendantType}
          setAttendantType={setAttendantType}
          date={date}
          setDate={setDate}
          flightListState={flightListState}
          setFlightListState={setFlightListState}
          activeInput={activeInput}
          setActiveInput={setActiveInput}
          flightList={flightList}
          activeDate={activeDate}
          setActiveDate={setActiveDate}
          passengerNumbers={passengerNumbers}
          setPassengerNumbers={setPassengerNumbers}
          searchDataHandler={submitDataHandler}
        />
      ) : (
        <>
          <HeaderNavSearch />
          {bookingType !== "modify" && (
            <ModifySearchBar
              tripType={tripType}
              setTripType={setTripType}
              departureCityValue={departureLocation}
              setDepartureCityValue={setDepartureLocation}
              arrivalCityValue={arrivalLocation}
              setArrivalCityValue={setArrivalLocation}
              dateFromState={flightDate}
              passengerNumbers={passengerNumbers}
              setPassengerNumbers={setPassengerNumbers}
            />
          )}
        </>
      )}

      <main className="w-full px-6">
        <div className="choose-your-flight">
          <p className="text-lg font-normal mb-2 text-left">
            Choose Your Flight
          </p>
          <p className="text-xs font-normal">
            Select your flight time and fare below
          </p>

          {/* <div className="week-month">
            <button className="btn-week border bg-primary text-white">
              Week
            </button>
            <button className="btn-month border" onClick={handleViewMonth}>
              Month
            </button>
          </div> */}

          {/* <div className='dates-carousel-box m-auto mt-12 pl-6 pr-6'>
            <Slider {...SliderSettings(6)}>
              {CAROUSEL_BOX_DATES.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`carousel-unit flex flex-col justify-center border ${item.customClass}`}
                  >
                    <p className='text-center text-xs font350 mb-1'>
                      {item?.date}
                    </p>
                    <p className='text-center text-xxs font325'>
                      {item?.availability}
                    </p>
                  </div>
                );
              })}
            </Slider>
          </div> */}
        </div>

        <div className="all-flights">
          {schedule?.direct?.length > 0 || schedule?.connecting?.length > 0 ? (
            <div className="onward-heading">
              <img src={flightUpSvg} alt="flight icon" />
              <span className="text-base ml-4">
                Departure - <span>{schedule?.direct?.[0]?.origin?.city}</span>{" "}
                to <span>{schedule?.direct?.[0]?.destination?.city}</span>
              </span>
            </div>
          ) : !schedule?.direct?.length && !schedule?.connecting?.length ? (
            <div className="onward-heading">
              <img src={flightUpSvg} alt="flight icon" />
              <span className="text-base ml-4">Departure</span>
            </div>
          ) : (
            <SkeletonComp height="100%" width="40%" customClass="mb-10 mt-4" />
          )}

          {schedule?.direct?.length || schedule?.connecting?.length ? (
            schedule?.direct
              .filter((flight) => flight?.availability)
              .map((selectedOnwardFlight, index) => {
                return (
                  <FlightResultDeskView
                    selectedOnwardFlight={selectedOnwardFlight}
                    handleFlightSelect={handleFlightSelect}
                    selectBtnColor={selectBtnColor}
                    setSelectBtnColor={setSelectBtnColor}
                    key={index}
                  />
                );
              })
          ) : (schedule?.direct?.length === 0 &&
              schedule?.connecting?.length === 0) ||
            openToast?.open ? (
            <>
              <NoResults type="desktop" />
              {(returnSchedule?.direct?.length > 0 ||
                returnSchedule?.connecting?.length > 0) && (
                <div className="text-center mt-4">
                  <Button
                    label={"modify with one way flight"}
                    bgColor={primary500}
                    color={white500}
                    onClickHandler={() => handleRoundtripToOneway("noOnward")}
                  />
                </div>
              )}
            </>
          ) : (
            <SkeletonComp
              customClass="skeleton-flight-result-desk"
              height="15rem"
            />
          )}

          {currentParams?.tripType === tripTypes.R && (
            <>
              {returnSchedule?.direct?.length > 0 ||
              returnSchedule?.connecting?.length > 0 ? (
                <div className="onward-heading">
                  <img src={flightUpSvg} alt="flight icon" />
                  <span className="text-base ml-4">
                    Return -{" "}
                    <span>{returnSchedule?.direct[0]?.origin?.city}</span> to{" "}
                    <span>{returnSchedule?.direct[0]?.destination?.city}</span>
                  </span>
                </div>
              ) : Boolean(
                  !returnSchedule?.direct?.length &&
                    !returnSchedule?.connecting?.length
                ) ? (
                <div className="onward-heading">
                  <img src={flightUpSvg} alt="flight icon" />
                  <span className="text-base ml-4">
                    Return
                    {Boolean(
                      schedule?.direct?.length || schedule?.connecting?.length
                    ) && (
                      <>
                        <span> - {schedule?.direct[0]?.destination?.city}</span>{" "}
                        to <span>{schedule?.direct[0]?.origin?.city}</span>
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <SkeletonComp
                  height="100%"
                  width="40%"
                  customClass="mb-10 mt-4"
                />
              )}

              {returnSchedule?.direct?.length > 0 ||
              returnSchedule?.connecting?.length > 0 ? (
                returnSchedule?.direct
                  .filter((flight) => flight?.availability)
                  .map((selectedReturnFlight, index) => {
                    return (
                      <FlightResultsReturn
                        selectedReturnFlight={selectedReturnFlight}
                        handleFlightSelectReturn={handleFlightSelectReturn}
                        key={index}
                      />
                    );
                  })
              ) : (returnSchedule?.direct?.length === 0 &&
                  returnSchedule?.connecting?.length === 0) ||
                openToast?.open ? (
                <>
                  <NoResults type="desktop" />
                  <div className="text-center mt-4">
                    {Boolean(
                      schedule?.direct?.length || schedule?.connecting?.length
                    ) && (
                      <Button
                        label={"modify with one way flight"}
                        bgColor={primary500}
                        color={white500}
                        onClickHandler={() =>
                          handleRoundtripToOneway("noReturn")
                        }
                      />
                    )}
                  </div>
                </>
              ) : (
                <SkeletonComp
                  customClass="skeleton-flight-result-desk"
                  height="15rem"
                />
              )}
            </>
          )}
        </div>
      </main>
    </div>
  );
};
