import React from "react";
import "./fleet.scss";
import "../../theme/stylesheet.scss";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import cardImage2 from '../../assets/images/fleetBanner2.png';
import cardImage3 from '../../assets/images/fleetBanner3.png';
import DataSummary from './Common/DataSummary/dataSummary';
import ProgressCard from './Common/ProgressCard/profressCard';
import { Container } from "@mui/system";

import { Navbar } from "../Navbar/Desktop/Navbar";
import { Navbar as MobileNavigationBar } from "../Navbar/Mobile/index";
import flybigLogoSearchNav from "../../assets/svgs/flybigLogoSearchNav.svg";
import { colorPalette } from "../../theme/color-palette"

import { useWindowSize } from "../../utils/utils";
import { Footer } from "../footer/Footer";

const { white500, primary500 } = colorPalette;


export const Fleet = () => {
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  return (
    <CssBaseline>
      {!isMobile ? <div className='nav-container-search-page bg-primary'>
        <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
      </div> :
        <MobileNavigationBar
          color={white500}
          bgColor={primary500}
          label={"Fleet"}
          showMenu={false} />
      }
      <Grid container direction="column" className="fleet_Container">
        {/* Banner Image */}
        <div className="fleet_BannerImage">
        </div>
      </Grid>

      {/* Fleet */}
      <Container className="fleet_Container">

        <Grid item container style={{ paddingLeft: '0px', marginTop: '44px' }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1.5rem",
              textAlign: "center",
              px: 3
            }}
          >
            <Grid alignItems="left">
              <p className="fleet_bannerTextOne">
                The most fuel efficient regional aircraft - ATR 72-600
              </p>
              <p className="fleet_Description" >
                IndiGo welcomes the opportunity to provide caring customer service to our customers with disabilities. To be able to assist you the best, we request you to notify us of your special needs/wheelchair request at least forty eight (48) hours prior to the schedule departure of your flight. While making your booking please let us know how we can best assist you and what seat will best accommodate your needs. If you arrive at the airport without a seat assignment, we will make every effort to provide the requested accommodation.
              </p>
            </Grid>


            {/* First choice for operators */}
            <ProgressCard
              heading='First choice for operators'
              description='ATR aircraft are suitable for all business models and all regions of the world, in any type of conditions (cold, hot, high altitude runways, harsh environments), and a wide range of airports (steep approach, unpaved airfields, short or narrow runways). This broad appeal and flexibility lead to strong asset values and wide acceptance of the aircraft in the airline and financing community.'
              progress={[
                {
                  ranking: '69g',
                  details: 'PER SEAT PER KM'
                },
                {
                  ranking: 'Ranked 1st',
                  details: 'ASSET IN REGIONAL'
                },
                {
                  ranking: '44-78',
                  details: 'SEATS'
                }
              ]}
            />

            <Grid sx={{ marginBottom: '80px' }}>
              <img src={cardImage2} width="100%" />
            </Grid>

            {/* The lowest seat-mile cost in regional aviation */}

            <ProgressCard
              heading='The lowest seat-mile cost in regional aviation'
              description='With its lighter structure, optimised speed and an engine designed for short sectors, the ATR 72-600 is, by far, the most fuel efficient regional aircraft. ATR’s fuel burn advantage drives lower costs and emissions and makes it the optimal aircraft for regional markets. The ATR 72-600 is the benchmark aircraft in the regional market with unbeatable economics. Operating costs on competing regional jets are at least 45% higher.'
              progress={[
                {
                  ranking: '$2 MILLION',
                  details: 'YEARLY SAVINGS ON OPERATING COST PER AIRCRAFT'
                },
                {
                  ranking: '45%',
                  details: 'TRIP COST ADVANTAGE'
                },
                {
                  ranking: '20%',
                  details: 'SEAT COST ADVANTAGE'
                }
              ]}
            />

            {/* Unique continuous cockpit innovation */}

            <Grid container>
              <p className="fleet_cardHeading"> Unique continuous cockpit innovation</p>
              <p className="fleet_cardInnerText">
                The ATR-600 series’ full glass cockpit provides a comprehensive and ergonomic working environment to the pilots for safe and efficient operations. It has raised the bar in human machine interfaces offering a high level of comfort and demonstrable efficiency gains.
                <br />Navigation, communication and surveillance functions are integrated into a modular avionics architecture. This state-of-the-art philosophy is open and evolutionary to allow new software and hardware functionalities to be inserted in the future independently: continuous development is the ATR DNA.
                <br /> Discover these unique features on-board a turboprop below. They are designed to improve over time.
              </p>
            </Grid>

            <Grid sx={{ marginBottom: '80px' }}>
              <img src={cardImage3} width="80%" />
            </Grid>

            {/* Data Summary */}
            <Grid container>
              <p className="cardHeading"> Data Summary</p>
              <DataSummary />
            </Grid>
          </Box>
        </Grid>
      </Container>
      <Footer/>
    </CssBaseline>
  );
};
