import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colorPalette } from "../../../../theme/color-palette";
import { ROUNDTRIP } from "../../../../utils/constant";
import { Navbar } from "../../../Navbar/Mobile";
import { Card } from "./Card";
import { CartDetails } from "../../../CartDetailsDrawer";
import drawer from "../../../../assets/svgs/drawer.svg";
import { MobileAmountFooter } from "../../../MobileAmountFooter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reviewBooking } from "../../../../Api/Booking";
import { selectedFlightHandler } from "../../../../Redux/CartReducer/cart.action";
import { sessionDataFormatter, useUrlExtract } from "../../../../utils/utils";
import { toastHandler } from "../../../../Redux/userReducer/user.action";
import { Footer } from "../../../footer/Footer";

const { white500, primary500 } = colorPalette;

export const SeatOptions = ({
  setSelectionStep,
  selectionStep,
  setStep,
  step,
  total,
  setTotal,
  faresDetails,
}) => {
  const [CartDetailsDrawer, setCartDetailsDrawer] = useState({
    bottom: false,
  });
  const [seatTypeData, setSeatTypeData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookingType, bookingId } = useUrlExtract("all");

  const {
    cart: {
      searchFare: { sessionId },
      cartData,
    },
    search: {
      searchDetails: { tripType, passengerNumbers },
    },
    user: { openToast },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectionStep === 1) {
      setSeatTypeData(cartData?.departure?.fares);
    } else {
      setSeatTypeData(cartData?.arrival?.fares);
    }
  }, [selectionStep]);

  const onClickHandler = () => {
    if (selectionStep === 1 && tripType === ROUNDTRIP && total?.total) {
      setSelectionStep(2);
      setStep(1);
    } else {
      let pricingData = sessionDataFormatter(cartData, tripType);
      pricingData.sessionId = sessionId;
      reviewBooking(pricingData)
        .then((res) => {
          const navigatePath =
            bookingType !== "modify"
              ? `/review-flight?sessionId=${sessionId}`
              : `/review-flight?sessionId=${sessionId}&bookingId=${bookingId}&bookingType=modify`;
          navigate(navigatePath);
        })
        .catch((err) => {
          dispatch(
            toastHandler({
              ...openToast,
              open: true,
              message: "Please Select Flight Type",
              severity: "error",
            })
          );
        });
    }
  };

  const onChangeHandler = (val, fare) => {
    let cartDataClone = JSON.parse(JSON.stringify(cartData));
    const { adult, children, infant } = passengerNumbers;
    if (selectionStep === 1) {
      let { departure } = cartDataClone;
      departure.fareBasicCode = val;
      setTotal({
        total: { ...fare.total },
        schedule: fare,
        passengers: {
          adult: adult,
          child: children,
          infant: infant,
          noOfPassengers: adult + children + infant,
        },
      });
    } else {
      let totalClone = { ...total };
      totalClone.returnSchedule = fare;
      for (let property in totalClone.total) {
        totalClone.total[property] =
          totalClone?.schedule?.total[property] +
          totalClone?.returnSchedule?.total[property];
      }
      setTotal({ ...totalClone });
      let { arrival } = cartDataClone;
      arrival.fareBasicCode = val;
    }
    dispatch(selectedFlightHandler(cartDataClone));
  };

  return (
    <div>
      <Navbar
        color={white500}
        bgColor={primary500}
        label={"Fare Options"}
        showMenu={false}
        onClose={() => {
          setStep(1);
          if (selectionStep === 1) {
            setTotal(null);
            dispatch(
              selectedFlightHandler({
                arrival: {},
                departure: {},
              })
            );
          }
        }}
      />
      <div className='bg-gray100 pt-4 h-screen overflow-auto '>
        <Container>
          <div className='mt-6'>
            {seatTypeData?.map((item, index) => (
              <Card
                key={index}
                item={item}
                onChangeHandler={onChangeHandler}
                faresDetails={faresDetails}
              />
            ))}
          </div>
        </Container>
      </div>
      <MobileAmountFooter
        label={selectionStep === 2 ? "Review" : "Continue"}
        onClickHandler={onClickHandler}
        drawer={drawer}
        setCartDetailsDrawer={setCartDetailsDrawer}
        data={total}
      />
      <CartDetails
        CartDetailsDrawer={CartDetailsDrawer}
        setCartDetailsDrawer={setCartDetailsDrawer}
        data={total}
        usedIn={"search-results"}
      />
      <Footer/>
    </div>
  );
};
