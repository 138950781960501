import React, { useEffect, useState } from "react";
import HeaderNavSearch from "../../searchResults/Desktop/headerResultPage/HeaderNavSearch";
import flightUpSvg from "../../../assets/svgs/flight-up.svg";
import "./passengerInfo.scss";
import { PassengerDetails } from "../common/passengerDetails";
import FareSummary from "../../reviewPage/common/FareSummary";
import PassengerContact from "../common/PassengerContact";
import { Checkbox, CircularProgress, Grid } from "@mui/material";
import { booking, sendPayment } from "../../../Api/Booking";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginPage } from "../../Login-Signup/Login/LoginPage";
import { colorPalette } from "../../../theme/color-palette";
import {
  bookingIdHandler,
  toastHandler,
} from "../../../Redux/userReducer/user.action";
import { ROUNDTRIP } from "../../../utils/constant";

export function PassengerInfo({
  passengersList,
  setPassengersList,
  sessionId,
  contactInfo,
  setContactInfo,
  addNewPassenger,
  reviewData,
  isAuth,
  travellerList,
  setTravellerList,
  travellerListClone,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user: { openToast },
    search,
    search: {
      searchDetails: { tripType },
    },
  } = useSelector((state) => state);

  const { primary500 } = colorPalette;

  useEffect(() => {
    const AllFilledArr = passengersList?.map((item) => {
      const { saveInfo, ...Obj } = item;
      return Object.values(Obj).every((val) => val);
    });
    const isAllFilled = AllFilledArr.every((item) => item);
    if (
      contactInfo?.email &&
      contactInfo?.contact?.number &&
      isChecked &&
      isAllFilled &&
      passengersList?.length ===
        search?.searchDetails?.passengerNumbers?.adult +
          search?.searchDetails?.passengerNumbers?.children +
          search?.searchDetails?.passengerNumbers?.infant
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    contactInfo,
    isChecked,
    passengersList,
    search?.searchDetails?.passengerNumbers,
  ]);

  const { schedule } = reviewData;
  const {
    origin: { city: originCity },
    destination: { city: destinationCity },
  } = schedule?.[0];

  const handleBack = () => {
    navigate(-1);
  };

  const handleOpen = () => {
    setOpen(true);
    setSignUpModal(false);
  };

  const handleClose = () => setOpen(false);

  const bookingHandler = async () => {
    const list = passengersList.map((obj) => {
      const { id, nationality, birthdate, ...formattedPassengerList } = obj;
      return {
        nationality: nationality?.isoCode,
        ...formattedPassengerList,
        ...(birthdate?.length > 0 && { birthdate }),
      };
    });
    const bookingReqBody = {
      sessionId: sessionId,
      ...contactInfo,
      passengers: list,
    };
    setIsLoading(true);

    const data = await sendPayment(bookingReqBody);

    window.location.href = data?.data?.url;

    // booking(bookingReqBody)
    //   .then(({ data }) => {
    //     dispatch(bookingIdHandler(data));
    //     return sendPayment(data?.id);
    //   })
    //   .then(({ data }) => {
    //     window.location.href = data?.url;
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     dispatch(
    //       toastHandler({
    //         ...openToast,
    //         open: true,
    //         message: err?.response?.data?.message,
    //         severity: "error",
    //       })
    //     );
    //   });
  };

  return (
    <>
      <div className="container-passenger-info-page w-full m-auto">
        <HeaderNavSearch />

        <main className="main-passenger-info m-auto">
          <div className="add-passenger-info mt-8 mb-8">
            <img src={flightUpSvg} alt="flight up" />
            {tripType === ROUNDTRIP ? (
              <span className="text-base font350 ml-4">
                {originCity} - {destinationCity} - {originCity}
              </span>
            ) : (
              <span className="text-base font350 ml-4">
                <span className="font-semibold">Departure</span> - {originCity}{" "}
                to {destinationCity}
              </span>
            )}
            <p className="text-xs font-normal mt-2 ml-9 mb-8">
              Add Guest Details
            </p>
          </div>

          <Grid container spacing={0} className="flex justify-between">
            <Grid
              item
              lg={7.5}
              md={7.5}
              className="passenger-information border p-6 mb-4"
            >
              <p className="text-primary  text-base font350 mb-2">
                Guest Information
              </p>
              <p className="text-sm font325 text-gray700 mb-6">
                Enter name as per Aadhar card/Passport or any Govt. ID proof
              </p>
              <PassengerDetails
                type="Adult"
                passengersList={passengersList}
                setPassengersList={setPassengersList}
                travellerList={travellerList?.filter(
                  (item) => item?.type === "Adult"
                )}
                setTravellerList={setTravellerList}
                travellerListClone={travellerListClone}
              />
              {search?.searchDetails?.passengerNumbers?.children > 0 && (
                <PassengerDetails
                  type="Child"
                  passengersList={passengersList}
                  setPassengersList={setPassengersList}
                  travellerList={travellerList?.filter(
                    (item) => item?.type === "Child"
                  )}
                  setTravellerList={setTravellerList}
                  travellerListClone={travellerListClone}
                />
              )}

              {search?.searchDetails?.passengerNumbers?.infant > 0 && (
                <PassengerDetails
                  type="Infant"
                  passengersList={passengersList}
                  setPassengersList={setPassengersList}
                  travellerList={travellerList?.filter(
                    (item) => item?.type === "Infant"
                  )}
                  setTravellerList={setTravellerList}
                  travellerListClone={travellerListClone}
                />
              )}
              {
                <PassengerContact
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                />
              }
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              className="fare-summary fare-summary-psngr border"
            >
              <FareSummary />
            </Grid>
          </Grid>
        </main>

        <div className="tnc-continue mt-10 ">
          <div className="flex items-center justify-center mb-4">
            <Checkbox
              size="small"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              sx={{
                "&.Mui-checked": {
                  color: primary500,
                },
              }}
            />
            <p className="text-xxs font-normal text-center line-full">
              By clicking "Continue" you agree to our Terms and Conditions :{" "}
              <a
                href="https://www.ibook.flybig.in/terms-and-condition"
                className="text-link500 cursor-pointer text-xxs font-normal"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                flybig Terms and Conditions
              </a>
            </p>
          </div>

          <div className="btngrps flex justify-center items-center">
            <button className="btn-cancel cursor-pointer" onClick={handleBack}>
              BACK
            </button>
            <button
              className="btn-contn-paymnt ml-6 uppercase cursor-pointer flex justify-center items-center"
              onClick={isAuth ? bookingHandler : handleOpen}
              disabled={isDisabled}
            >
              {isAuth ? "Continue to payment" : "Login to continue"}
              {isLoading && (
                <span className="ml-4">
                  <CircularProgress color="inherit" size={20} />
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      <LoginPage
        open={open}
        signUpModal={signUpModal}
        setSignUpModal={setSignUpModal}
        handleClose={handleClose}
      />
    </>
  );
}
