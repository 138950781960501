import React, { useEffect, useState } from "react";
import "./contact.scss";
import "../../theme/stylesheet.scss";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Container } from "@mui/system";

import { Navbar } from "../Navbar/Desktop/Navbar";
import { Navbar as MobileNavigationBar } from "../Navbar/Mobile/index";
import flybigLogoSearchNav from "../../assets/svgs/flybigLogoSearchNav.svg";
import { colorPalette } from "../../theme/color-palette";
import { useWindowSize } from "../../utils/utils";
import { searchUiSectionsSorted } from "../../Api/search";
import parse from "html-react-parser";
import { Footer } from "../footer/Footer";

const { white500, primary500 } = colorPalette;

export const Contact = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    searchUiSectionsSorted("Contact").then(({ data }) => {
      setContent(data?.[0]);
    });
  }, []);

  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;
  return (
    <CssBaseline>
      <Grid className="contactus">
        {!isMobile ? (
          <div className="nav-container-search-page bg-primary">
            <Navbar logo={flybigLogoSearchNav} customClass="navbar-search" />
          </div>
        ) : (
          <MobileNavigationBar
            color={white500}
            bgColor={primary500}
            label={"Fleet"}
            showMenu={false}
          />
        )}

        {/* Banner Image */}
        <Grid container direction="column">
          <img src={content?.bannerImage} alt="" />
        </Grid>

        <Container className="contactus">
          <p className="contactus_pageHeading">Contact us</p>
          <Grid
            container
            spacing={2}
            direction="row"
            className="contactus_feedbackborder"
          >
            {content?.details?.map((item) => {
              return (
                <>
                  <Grid item xs={12} md={6}>
                    <p className="contactus_heading">{item?.title}</p>
                    <div className="contactus_SubHeading">
                      {parse(item?.description?.text)}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <img src={item?.image?.link} width="100%" alt="" />
                  </Grid>
                </>
              );
            })}
          </Grid>

          <Grid
            item
            container
            spacing={2}
            direction="row"
            className="contactus_feedbackborder"
            sx={{
              marginTop: "79px",
              paddingBottom: "40px",
              // paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            {/* <Grid item xs={12} md={6}> */}
            <Grid>
              <p className="contactus_heading">Guest Support</p>
              <div className="contactus_SubHeading">
                <p>For any feedback reach out to us:</p>
                <div className="flex align-self-center">
                  <p className="contactus_textColor">
                    <svg
                      className="mr-5"
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-phone"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="#b72179"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                    </svg>{" "}
                    <a
                      // href="mailto: guest.relations@flybig.in"
                      className="link"
                    >
                      + 91 9910 655 655
                    </a>
                  </p>
                </div>
                <p className="contactus_textColor">
                  <svg
                    className="mr-5"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-mail"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="#b72179"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                    <path d="M3 7l9 6l9 -6" />
                  </svg>{" "}
                  <a href="mailto: guest.relations@flybig.in" className="link">
                    guest.relations@flybig.in
                  </a>
                </p>
                <p>
                  *Calls may be recorded for training and quality purposes and
                  for compliance with applicable regulatory requirements
                </p>
              </div>
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <p className="contactus_heading">Guest Feedback</p>
              <div className="contactus_SubHeading">
                <p>
                  For any feedback/suggestions/complaints or to reach out to our
                  Guest Relations Team, please write to us at
                </p>
                <p className="contactus_textColor">
                  {" "}
                  <a href="mailto: guest.relations@flybig.in" className="link">
                    guest.relations@flybig.in
                  </a>
                </p>
              </div>
            </Grid> */}

            {/* <Grid item xs={12} md={6}>
              <p className="contactus_heading">Guest Helpline</p>
              <div className="contactus_SubHeading">
                <p>
                  For any queries related to your travel, call our call center
                  on
                </p>
                <p className="contactus_textColor"> +91 9910 655 655</p>
              </div>
            </Grid> */}
          </Grid>

          <Grid className="contactus_grievanceRedressal">
            <p className="contactus_heading"> Grievance Redressal </p>
            <p className="contactus_SubHeading">
              In compliance with the Civil Aviation Requirement, Section -3, Air
              Transport, Series M, Part IV. You may write to the following
            </p>
            <div className="contactus_SubHeading">
              <p>
                {" "}
                Nodal Officer, Ms. Shivani Jain at{" "}
                <a href="mailto: nodalofficer@flybig.in" className="link">
                  nodalofficer@flybig.in
                </a>{" "}
              </p>
              <p>
                {" "}
                Appellate authority, Mr. Rajiv Sharma at{" "}
                <a href="mailto: appellateauthority@flybig.in" className="link">
                  appellateauthority@flybig.in
                </a>{" "}
              </p>
              <p>
                {" "}
                You may contact them from Monday – Friday between{" "}
                <b>0930 hrs – 1730 hrs</b>.
              </p>
            </div>
          </Grid>

          <Grid className="contactus_grievanceRedressal">
            <p className="contactus_heading"> Group Bookings: </p>
            <p className="contactus_SubHeading">
              Kindly direct all your group reservation queries to {"  "}
              <a href="mailto: groupdesk@flybig.in" className="link">
                groupdesk@flybig.in {"  "}
              </a>
              <p>
                Our team will promptly assist you with all possible assistance
                in making your journey truly memorable with us. We thank you for
                your time today
              </p>
            </p>
          </Grid>

          <Grid className="contactus_grievanceRedressal">
            <p className="contactus_heading"> Advertise with Us: </p>
            <p className="contactus_SubHeading">
              For any enquiries related to Promotions, Media or Ancillary
              Tie-ups – please email us at{" "}
              <a href="mailto: info@flybig.in" className="link">
                info@flybig.in
              </a>
            </p>
          </Grid>
        </Container>
      </Grid>
      <Footer/>
    </CssBaseline>
  );
};
