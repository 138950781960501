import React from "react";
import { Navbar as MobileNavbar } from "../components/Navbar/Mobile";
import { Navbar } from "../components/Navbar/Desktop/Navbar";
import { CheckInStepper } from "../components/WebCheckIn/desktop/Index";
import { colorPalette } from "../theme/color-palette";
import { useUrlExtract, useWindowSize } from "../utils/utils";
import { CheckInSuccess } from "../components/WebCheckIn/desktop/CheckInSuccess";
import flybigLogoSearchNav from "../assets/svgs/flybigLogoSearchNav.svg";
import PositionedSnackbar from "../components/common/Toast";
import { Footer } from "../components/footer/Footer";

export const CheckInView = () => {
  const [innerWidth] = useWindowSize();
  let isMobile = innerWidth < 640 && innerWidth !== 0;

  const successStatus = useUrlExtract("payment");

  const { white500, primary500 } = colorPalette;

  return (
    <>
      <div className='bg-primary m-auto'>
        {isMobile ? (
          <MobileNavbar
            color={white500}
            bgColor={primary500}
            label={"Confirmation"}
            showMenu={false}
          />
        ) : (
          <Navbar logo={flybigLogoSearchNav} customClass='navbar-search' />
        )}
      </div>
      {successStatus ? <CheckInSuccess /> : <CheckInStepper />}
      <PositionedSnackbar />
      <Footer/>
    </>
  );
};
