import { Box, CircularProgress, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DATE_FORMAT,
  PASSENGER_INFO_CONFIRM,
  tripTypes,
} from "../../../utils/constant";
import "./confirmationPageDesk.scss";
import { TabPanelsComp } from "./common/tabPanels/TabPanelsComp";
import {
  getFormattedDate,
  setGender,
  useUrlExtract,
} from "../../../utils/utils";
import { Navbar } from "../../Navbar/Desktop/Navbar";
import flybigLogoSearchNav from "../../../assets/svgs/flybigLogoSearchNav.svg";
import { SkeletonComp } from "../../common/Skeleton/Skeleton";
import { bookingDetailsEmail, bookingDetailsSms } from "../../../Api/Booking";
import { DesktopToast } from "../../common/Toast/DesktopToast";
import { Button } from "../../common/Button";
import { colorPalette } from "../../../theme/color-palette";
import { useNavigate } from "react-router-dom";
import DefaultToast from "../../common/Toast/DefaultToast";
import { Footer } from "../../footer/Footer";

export default function ConfirmationPageDesk({ bookingDetails }) {
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({
    email: false,
    sms: false,
  });
  const [toastMsg, setToastMsg] = useState({
    email: "",
    sms: "",
  });
  const [bookingDetailsState, setBookingDetailsState] = useState("");
  const [severity, setSeverity] = useState("success");
  const successStatus = useUrlExtract("payment");
  const [message, setMessage] = useState("");
  const bookingId = useUrlExtract("bookingId")?.bookingId;
  const navigate = useNavigate();
  useEffect(() => {
    setBookingDetailsState(bookingDetails);
  }, [bookingDetails]);

  const { primary500, white500 } = colorPalette;

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    if (tabValue === index) {
      return {
        id: `simple-tab-${index}`,
        className: `active-tab-custom`,
      };
    } else {
      return { id: `simple-tab-${index}` };
    }
  }

  let { passengers } = bookingDetailsState;

  const loggedUser = JSON.parse(localStorage.getItem("user"));

  const handleSendEmail = () => {
    setIsLoading((prev) => ({ ...prev, email: true }));
    bookingDetailsEmail(bookingId)
      .then(() => {
        setIsLoading((prev) => ({ ...prev, email: false }));
        setToastMsg((prev) => ({ ...prev, email: "success" }));
        setOpen(true);
        setSeverity("success");
        setMessage("Email Sent Successfully");
        setTimeout(() => setOpen(false), 2000);
      })
      .catch(() => {
        setIsLoading((prev) => ({ ...prev, email: false }));
        setToastMsg((prev) => ({ ...prev, email: "failure" }));
        setOpen(true);
        setSeverity("error");
        setMessage("Unable to send Email");
        setTimeout(() => setOpen(false), 2000);
      });
  };

  const handleSendSms = () => {
    setIsLoading((prev) => ({ ...prev, sms: true }));
    bookingDetailsSms(bookingId)
      .then(() => {
        setIsLoading((prev) => ({ ...prev, sms: false }));
        setToastMsg((prev) => ({ ...prev, sms: "success" }));
        setOpen(true);
        setSeverity("success");
        setMessage("SMS Send Successfully");
        setTimeout(() => setOpen(false), 2000);
      })
      .catch(() => {
        setIsLoading((prev) => ({ ...prev, sms: false }));
        setToastMsg((prev) => ({ ...prev, sms: "failure" }));
        setOpen(true);
        setSeverity("error");
        setMessage("Unable to send SMS");
        setTimeout(() => setOpen(false), 2000);
      });
  };

  const handleReturnHome = () => {
    navigate("/");
  };

  return (
    <>
      {!bookingDetailsState ? (
        <SkeletonComp
          width="80vw"
          height="80vh"
          varient="rectangular"
          customClass="w-full m-auto"
        />
      ) : (
        <div className="confirmation-page-container w-full m-auto">
          <div className="nav-container-search-page bg-primary">
            <Navbar logo={flybigLogoSearchNav} customClass="navbar-search" />
          </div>
          {toastMsg?.email === "success" && (
            <DesktopToast type="success" toastMessage="email" />
          )}
          {toastMsg?.email === "failure" && (
            <DesktopToast type="failure" toastMessage="email" />
          )}
          {toastMsg?.sms === "success" && (
            <DesktopToast type="success" toastMessage="sms" />
          )}
          {toastMsg?.sms === "failure" && (
            <DesktopToast type="failure" toastMessage="sms" />
          )}

          <div className="cnfrm-strt m-auto p-4 flex justify-between">
            <section className="mr-10">
              <p className="text-sm font350 mt-4 mb-6">
                Hello {loggedUser?.user?.name},
              </p>
              {successStatus === "true" ? (
                <>
                  <p className="text-base font350 mb-2 text-green600 text-green600">
                    "Congratulations! Your Booking has been Confirmed."
                  </p>
                  <p className="text-sm font350 mb-10">
                    Your booking is confirmed and your ticket copy is attached
                    herewith for your reference. Please carry the printout of
                    your e-ticket to the airline check-in counter.
                  </p>
                </>
              ) : (
                <p className="text-base font350  text-red mb-2">
                  Sorry! Your booking could not be completed. Any amount
                  deducted will be refunded within 5-7 working days. We
                  apologise for any inconvenience caused.
                </p>
              )}
            </section>

            {successStatus === "true" ? (
              <section className="pnr-box flex flex-col justify-center items-center mt-4">
                <p className="font350 text-center mb-2">Your PNR</p>
                <p className="font350 text-base text-center">
                  {bookingDetailsState?.bookingId}
                </p>
              </section>
            ) : (
              <section className="pnr-box-failure flex justify-center items-center border">
                <p className="font350 text-center">Booking Failed.</p>
              </section>
            )}
          </div>

          <div>
            {successStatus === "true" ? (
              <div className="imp-msg m-auto p-4">
                <p className="text-sm font350 mb-1">Important</p>
                <ul>
                  <li className="text-xs font325">
                    For choosing your choice of seat or purchase of prepaid
                    baggage please visit our website www.flybig.in or
                    alternatively call our guest helpline number 9910655655
                  </li>
                  <li className="text-xs font325">
                    You are requested to complete web check-in formalities
                    before arriving at the airport, web check-in opens 72 hours
                    prior up until 2hours to departure
                  </li>
                  <li className="text-xs font325">
                    Please note check-in counters close 45 minutes prior to
                    departure
                  </li>
                  <li className="text-xs font325">
                    It is mandatory for all passengers to carry valid photo ID
                    proof in order to enter the airport and show at the time of
                    check-in. Permissible ID proofs include - Aadhaar Card,
                    Passport or any other government recognized ID proof. For
                    infant travelers (0-2 years), it is mandatory to carry the
                    birth certificate as a proof
                  </li>
                </ul>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <section>
            {successStatus === "true" ? (
              <div>
                <Box className="tabs-box-flight-confirm border">
                  <Box sx={{ margin: 0 }}>
                    <Tabs value={tabValue} onChange={handleChangeTabs}>
                      <Tab label="Departure" {...a11yProps(0)} />
                      {bookingDetails?.tripType !== tripTypes?.O && (
                        <Tab label="Return" {...a11yProps(1)} />
                      )}
                    </Tabs>
                  </Box>
                  <TabPanelsComp
                    tabValue={tabValue}
                    bookingDetailsState={bookingDetailsState}
                  />
                </Box>

                <div className="traveller-info-confirm border m-auto mt-8 mb-10 flex flex-col justify-between">
                  <div className="traveller-info-confirm-header border bg-black text-white p-3 pl-8 font-normal text-xs">
                    GUEST INFORMATION
                  </div>

                  <Grid container className="flex justify-evenly p-4">
                    <Grid item lg={0}></Grid>
                    {PASSENGER_INFO_CONFIRM?.header.map((item, index) => {
                      return (
                        <Grid
                          item
                          lg={2}
                          key={index}
                          className="font-normal text-xs uppercase text-left"
                        >
                          {item}
                        </Grid>
                      );
                    })}
                  </Grid>

                  {passengers &&
                    passengers.map((item, index) => {
                      return (
                        <>
                          <Grid container className="flex justify-evenly p-4">
                            <Grid item lg={0} className="text-right">
                              {index + 1}
                            </Grid>
                            <Grid
                              item
                              lg={2}
                              key={index}
                              className="font-normal text-xs gotham-book text-left"
                            >
                              {item?.firstName} {item?.lastName}
                            </Grid>
                            <Grid
                              item
                              lg={2}
                              key={index}
                              className="font-normal text-xs gotham-book text-left"
                            >
                              {setGender(item?.salutation)}
                            </Grid>
                            <Grid
                              item
                              lg={2}
                              key={index}
                              className="font-normal text-xs gotham-book text-left"
                            >
                              {getFormattedDate(
                                item?.birthdate,
                                DATE_FORMAT?.DATE7
                              )}
                            </Grid>
                            <Grid
                              item
                              lg={2}
                              key={index}
                              className="font-normal text-xs gotham-book text-left"
                            >
                              {
                                bookingDetailsState?.tickets?.filter((value) =>
                                  value?.passengerName.includes(
                                    item?.firstName?.toUpperCase()
                                  )
                                )?.[0]?.ticket
                              }
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                </div>
              </div>
            ) : (
              ""
            )}
          </section>

          <div className="btn-group-confirm flex justify-center items-center">
            <Button
              bgColor={primary500}
              color={white500}
              fontWeight={700}
              disabled={successStatus === "true" ? false : true}
              onClickHandler={handleSendEmail}
              label={
                isLoading?.email ? (
                  <>
                    "SEND TO EMAIL"
                    <span className="ml-4 mt-1">
                      <CircularProgress color="inherit" size={20} />
                    </span>
                  </>
                ) : (
                  "SEND TO EMAIL"
                )
              }
            />
            <Button
              bgColor={primary500}
              color={white500}
              fontWeight={700}
              disabled={successStatus === "true" ? false : true}
              onClickHandler={handleSendSms}
              label={
                isLoading?.sms ? (
                  <>
                    "SMS TICKET"
                    <span className="ml-4 mt-1">
                      <CircularProgress color="inherit" size={20} />
                    </span>
                  </>
                ) : (
                  "SMS TICKET"
                )
              }
            />
            <Button
              bgColor={primary500}
              color={white500}
              fontWeight={700}
              onClickHandler={handleReturnHome}
              label={"GO TO HOME"}
            />
          </div>
          <DefaultToast open={open} severity={severity} message={message} />
          <Footer />
        </div>
      )}
    </>
  );
}
