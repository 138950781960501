import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchFare, searchModifiedFareDetails } from "../Api/search";
import PositionedSnackbar from "../components/common/Toast";
import { SearchPage } from "../components/searchResults/SearchPage";
import {
  flightFareHandler,
  loaderHandler,
} from "../Redux/CartReducer/cart.action";
import { toastHandler } from "../Redux/userReducer/user.action";
import { useUrlExtract } from "../utils/utils";

export const SearchView = () => {
  const [faresResponse, setFaresResponse] = useState({});

  let currentParams = useUrlExtract("search");
  const { bookingType, bookingId } = useUrlExtract("all");
  const dispatch = useDispatch();

  const {
    user: { openToast },
  } = useSelector((state) => state);

  const modifyBody =
    currentParams?.tripType === "RoundTrip"
      ? {
          departureDate: currentParams?.departureDate,
          returnDate: currentParams?.returnDate,
        }
      : { departureDate: currentParams?.departureDate };

  useEffect(() => {
    dispatch(loaderHandler(true));
    dispatch(flightFareHandler(faresResponse));
  }, [faresResponse]);

  useEffect(() => {
    dispatch(loaderHandler(true));
  }, [
    currentParams?.origin,
    currentParams?.destination,
    currentParams?.departureDate,
    currentParams?.returnDate,
    currentParams?.passengers?.noOfPassengers,
    currentParams?.tripType,
  ]);

  useEffect(() => {
    console.log(`#2024200122515199 currentParams`, currentParams);

    bookingType !== "modify"
      ? searchFare(currentParams)
          .then((response) => {
            dispatch(loaderHandler(false));

            setFaresResponse(response?.data);
          })
          .catch((err) => {
            dispatch(loaderHandler(false));
            dispatch(
              toastHandler({
                ...openToast,
                open: true,
                message:
                  err?.response?.data?.message || "Something went wrong ...",
                severity: "error",
              })
            );
          })
      : searchModifiedFareDetails(bookingId, modifyBody)
          .then(({ data }) => {
            dispatch(loaderHandler(false));
            setFaresResponse(data);
          })
          .catch((err) => {
            dispatch(loaderHandler(false));
            dispatch(
              toastHandler({
                ...openToast,
                open: true,
                message:
                  err?.response?.data?.message || "Something went wrong ...",
                severity: "error",
              })
            );
          });
  }, [
    currentParams?.origin,
    currentParams?.destination,
    currentParams?.departureDate,
    currentParams?.returnDate,
    currentParams?.passengers?.noOfPassengers,
    currentParams?.tripType,
  ]);

  return (
    <div>
      <SearchPage faresResponse={faresResponse} />
      <PositionedSnackbar />
    </div>
  );
};
